import { observer } from "mobx-react";
import { Component } from "react";

import { Flex, Heading, IconArrowNestLine, IconSubaccountsLine, Table as InstTable, Link, Text, View } from "@instructure/ui";

import { ATColumnSpec, AsyncTable } from "@toolkit/components/AsyncTable";
import { env } from "@toolkit/environment";
import { DataPage } from "@toolkit/util/DataFetcher";
import { emulate_instui } from "@toolkit/util/index";

import { API } from "@/api";
import { SmartLink } from "@/components/SmartLink";
import { TableTitle } from "@/components/TableTitle";
import { Account, transform_accounts_page } from "@/types/account";
import { AccountByline } from "./components/AccountHeader";
import { AccountStatusPill } from "./components/Pills";
import { SetupButton } from "./components/SetupButton";

const COLUMNS: ATColumnSpec[] = [
    { key: "name", title: "Canvas Sub-Account" },
    { key: "setup", title: "", width: "0" },
]

@observer
export class SubAccountList extends Component {
    accounts_fetcher = API.fetcher<DataPage<Account>>("api/v1/accounts?page=all", {
        transform: transform_accounts_page,
    })

    render() {
        return <>
            <Heading level="h3" margin="medium 0">Integration Account Index</Heading>

            <TableTitle title="Sub-Accounts" />

            <AsyncTable
                hover
                columns={COLUMNS}
                fetcher={this.accounts_fetcher}
                renderRow={data => <Row data={data} />}
            />
        </>
    }
}


const AccountName = ({ data }: { data: Account }) => {
    if (!data.clever_link) return <Text>{data.name}</Text>;
    return <SmartLink to={`/accounts/${data.id}`}>{data.name}</SmartLink>
}

@observer
@emulate_instui(InstTable.Row)
class Row extends Component<{ data: Account }, {}> {

    render() {
        const { data: row, ...rest } = this.props;
        const level = row.ancestry_depth - 1;
        const indent = Math.max(0, level - 1);

        return <>
            <InstTable.Row {...rest}>
                <InstTable.Cell>
                    <Flex direction="row">
                        <Flex.Item shouldGrow>
                            <div style={{ paddingLeft: `${indent * 20}px` }}>
                                <Flex alignItems="baseline">
                                    {level > 0 && <Flex.Item>
                                        <View display="block" margin="0 x-small 0 0">
                                            <IconArrowNestLine />
                                        </View>
                                    </Flex.Item>}
                                    <Flex.Item>
                                        <View display="block" margin="0 x-small 0 0">
                                            <Link target="_blank" href={`${env.canvas_url}/accounts/${row.id}/settings`}>
                                                <IconSubaccountsLine />
                                            </Link>
                                        </View>
                                    </Flex.Item>

                                    <Flex.Item>
                                        <div>
                                            <AccountName data={row} />
                                            <View margin="0 0 0 x-small">
                                                <AccountStatusPill account={row} />
                                            </View>
                                        </div>

                                        <View as="div" margin="x-small 0 0 0">
                                            <Text as="div" size="small">
                                                <AccountByline detailed data={row} />
                                            </Text>
                                        </View>
                                    </Flex.Item>
                                </Flex>
                            </div>
                        </Flex.Item>
                        <Flex.Item>
                            {!row.clever_link && <>
                                <SetupButton account={row} />
                            </>}
                        </Flex.Item>
                    </Flex>
                </InstTable.Cell>
            </InstTable.Row>
        </>
    }
}
