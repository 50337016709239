import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";

import { Flex, RadioInput, RadioInputGroup, TextInput } from "@instructure/ui";

import { FormSubmitDisabledReason, showFormPrompt, validators } from "@inst_proserv/formkit";

import { Field } from "@matchlighter/cognizant_forms/components";

import { API } from "@/api";
import { Account, getAccountType, getDistrictAccount } from "@/types/account";
import { AccountHeader } from "./components/AccountHeader";

const clever_validator = (value: string) => {
    if (!value || !value.match(/^[0-9a-f]{24}$/)) {
        return "Must be a 24-character hexadecimal string";
    }
}

@observer
export class SubAccountSettings extends Component<{ account: Account }> {
    static showAsModal = async (account: Account) => {
        const accType = getAccountType(account);
        return await showFormPrompt(<SubAccountSettings account={account} />, {
            title: `Link Clever ${accType}`,
            size: "small",
            submit(values: any, form) {
                return API.protectedRequest({ action: "updating settings", form}, async () => {
                    const response = await API.post(`api/v1/accounts/${account.id}/settings`, {
                        clever_resource_type: `Clever${accType}`,
                        ...values,
                    })
                    runInAction(() => {
                        Object.assign(account, response.data);
                    })
                })
            },
        })
    }

    render() {
        const { account } = this.props;
        const type = getAccountType(account);
        const districtAccount = getDistrictAccount(account);

        const link = account.clever_link

        return <>
            <AccountHeader account={this.props.account} margin="small 0 medium" />

            <div style={{ display: "flex", gap: "20px", }}>
                <Flex.Item shouldGrow>
                    <Field name="clever_resource_id" validate={clever_validator} initialValue={account.clever_link?.clever_id}>
                        <TextInput renderLabel={`Clever ${type} Unique ID`} />
                    </Field>
                </Flex.Item>
                <Flex.Item>
                    <Field name="sso_provider" validate={validators.isPresent} initialValue={link?.sso_provider ?? districtAccount?.clever_link?.sso_provider}>
                        <RadioInputGroup name="sso_provider" description="SSO Provider">
                            <RadioInput label="Google" value="google" />
                            <RadioInput label="Microsoft" value="microsoft" />
                        </RadioInputGroup>
                    </Field>
                </Flex.Item>
            </div>
            <FormSubmitDisabledReason />
        </>
    }
}
