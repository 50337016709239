import React from 'react';
import { Link as RouteLink, LinkProps as RouteLinkProps } from 'react-router-dom';

import { Link } from '@instructure/ui-link';
import { Button } from '@instructure/ui-buttons'

const ABS_URL = new RegExp('^(?:[a-z]+:)?//', 'i');
export const resolveLink = (link: RouteLinkProps['to'], inProps?, children?) => {
    const { active, button, ...props } = inProps;
    if (children) props['children'] = children;

    if (button) {
        props['variant'] = props['variant'] || 'link';
    } else {
        if (props['isWithinText'] == undefined) props['isWithinText'] = false;
    }

    const Component: any = button ? Button : Link;

    if (active === false) return <span {...props} />;
    if (typeof link == 'function') return <Component onClick={link} {...props} />
    if (link && typeof link == 'string' && ABS_URL.test(link)) return <Component target="_parent" href={link} {...props} />
    if (link) return <Component as={RouteLink} to={link} {...props} />
    return <span {...props} />;
}

interface SmartLinkProps extends Partial<RouteLinkProps> {
    to?: RouteLinkProps['to'],
    active?: boolean,
    button?: boolean,

    [key: string]: any
}

export const SmartLink: React.FunctionComponent<SmartLinkProps> = ({ to, ...rest }) => {
    return resolveLink(to, rest);
}
