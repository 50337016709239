
export function groupBy<T>(arr: T[], key: string | ((item: T) => string)): { [key: string]: T[] } {
    const result = {};
    for (let item of arr) {
        const k = typeof key == 'string' ? item[key] : key(item);
        if (!result[k]) result[k] = [];
        result[k].push(item);
    }
    return result;
}

export function default_calc_property<O, T>(obj: O, prop: keyof O, getter: (object: O) => T) {
    if ((obj as Object).hasOwnProperty(prop)) return;

    Object.defineProperty(obj, prop, {
        get: function () { return getter(this) },
        set: (value: T) => {
            Object.defineProperty(obj, prop, { value, writable: true });
        },
        enumerable: true,
        configurable: true,
    })
}
