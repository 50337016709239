
import { Component } from "react";

import { AlertManager, AlertProvider, AlertsContainer, DeclAlert } from "@inst_proserv/toolkit/components/AlertManager";
import { Button, Heading, IconCanvasLogoLine, Link, Text, View } from "@instructure/ui";

import '@/bootstrap';
import '@/screen.scss';

import '@/routes/login/screen.scss';
import { env } from "@toolkit/environment";

export default class LoginLanding extends Component {
    componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');
        AlertManager.default.addAlert(message, {
            level: 'error',
        })
    }

    render() {
        return <>
            <AlertProvider>
                <AlertsContainer />

                <div id="login-page">
                    <div className="header">
                        <div className="image-wrapper">
                            <img src="/resources/GLWordmark.svg" alt="Gradient Learning Logo" />
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <div className="side-image">
                            <div className="image-wrapper">
                                <img className="logo" src="/resources/GLWordmark.svg" alt="Gradient Learning Logo" />
                            </div>
                            <img className="banner" src="/resources/People_TransparentBackground_fit.png" alt="Gradient Learning Side Banner" />
                        </div>
                        <div className="form">
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </AlertProvider>
        </>
    }
}

class LoginForm extends Component {

    render() {
        return <>
            <Heading level="h2" margin="0 0 small">Sign In</Heading>
            <Text color="secondary">Select an authentication method below:</Text>

            <Heading level="h3" margin="large 0 small">Students / Faculty</Heading>
            <Text color="secondary" size="small">
                Select your school/district authentication and document provider below:
            </Text>
            <Button
                display="block" size="large" margin="small 0"
                href={`${env.canvas_url}/login/google`}
                renderIcon={<img src="/resources/google.png" />}
            >
                Google
            </Button>
            <Button
                display="block" size="large" margin="small 0"
                href={`${env.canvas_url}/login/microsoft`}
                renderIcon={<img src="/resources/microsoft.png" />}
            >
                Microsoft
            </Button>

            <Heading level="h3" margin="large 0 small">Parents / Observers</Heading>
            <Text color="secondary" size="small">
                To view your child/student’s progress, parents or guardians must register and set up their Canvas username and password.  If you have completed registration and have your Canvas username & password, select Canvas below.
            </Text>
            <Button
                display="block" size="large" margin="small 0"
                href={`${env.canvas_url}/login/canvas`}
                renderIcon={<span style={{ color: "#E53C2E", fontSize: "22px" }}><IconCanvasLogoLine /></span>}
            >
                Canvas
            </Button>

            <View as="div" margin="large 0 0" textAlign="center">
                <Link href="https://wholestudent.gradientlearning.org/" isWithinText={false}>Terms and Conditions</Link>
                &nbsp;|&nbsp;
                <Link href="https://gradientlearning.org/privacy-commitments/" isWithinText={false}>Privacy Policy</Link>
            </View>
        </>
    }
}
