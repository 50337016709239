import { action } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";

import { Button, IconAdminToolsLine, Tooltip } from "@instructure/ui";

import { Account } from "@/types/account";
import { SubAccountSettings } from "../SubAccountSettings";
import { getDistrictAccount } from "@/types/account";

@observer
export class SetupButton extends Component<{ account: Account, callback?: (result) => void }> {
    @action.bound
    async handleSetup() {
        const result = await SubAccountSettings.showAsModal(this.props.account);
        this.props.callback?.(result);
    }

    render() {
        const { account } = this.props;

        let disabled_reason: string;
        if (!account.sis_id) disabled_reason = "This account does not have a SIS ID";

        if (account.ancestry_depth > 1 && !getDistrictAccount(account)) {
            disabled_reason = "District account must be configured first";
        }

        const btn = <Button
            interaction={disabled_reason ? "disabled" : "enabled"}
            size="small"
            renderIcon={<IconAdminToolsLine />}
            withBackground={false}
            color="primary"
            onClick={this.handleSetup}
        >
            Set Up
        </Button>;

        if (disabled_reason) {
            return <Tooltip renderTip={disabled_reason}>{btn}</Tooltip>;
        } else {
            return btn;
        }
    }
}
