import { observable } from "mobx";

import { DataPage } from "@inst_proserv/toolkit/util/DataFetcher";

import { CleverLink } from "./common";

export interface Account {
    id: number;
    parent_account_id: number;
    ancestry_depth: number;
    sis_id: string;
    name: string;
    clever_link: CleverLink;

    parent_account?: Account;
    district_account?: Account;
}

// I'm debating if I like the functional approach on these, or if I'd prefer hydrating the raw API responses into class instances...

export function getDistrictAccount(acc: Account) {
    if (acc.clever_link?.type === "CleverDistrict") return acc;
    return acc.parent_account ? getDistrictAccount(acc.parent_account) : null;
}

export function getAccountType(acc: Account): "School" | "District" {
    if (acc.clever_link) acc.clever_link.type === "CleverSchool" ? "School" : "District";
    if (acc.district_account) return "School";
    return getDistrictAccount(acc) ? "School" : "District";
}

export function transform_accounts_page(data: DataPage<Account>) {
    const accs_by_id = {};
    data.items = (data.items || []).map(acc => {
        acc = observable(acc);
        accs_by_id[acc.id] = acc;
        acc.parent_account = accs_by_id[acc.parent_account_id];
        return acc;
    })
    return data;
}
