import axios, { Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import deepEqual from 'deep-eql';
import { observable } from 'mobx';
import { Class, Promisable } from 'type-fest';
// import { FormApi } from 'final-form';

// import { transformHttpErrors } from '@inst_proserv/formkit';
import { AlertManager } from '@toolkit/components/AlertManager';
import { DataPage } from '@toolkit/components/DataPaginator';
import { DataFetcher } from '@toolkit/util/DataFetcher';
import { transformHttpErrors } from '@inst_proserv/formkit';

import { urlJoin } from '@matchlighter/common_library/strings';
import { Form } from '@matchlighter/cognizant_forms';

import { env } from './environment';
import { captureException } from '@sentry/browser';

function isSomeHttpError(err: Error): err is AxiosError {
    return (err as any).response !== undefined;
}

export function isHttpError(err: Error, code?: number | string | RegExp): err is AxiosError {
    if (isSomeHttpError(err)) {
        if (!code) return true;

        if (typeof code == 'number') return err.response.status == code;
        if (typeof code == 'string') code = new RegExp(`^${code.replace(/x/g, '\\d')}$`);

        return code.test(String(err.response.status));
    } else {
        return false;
    }
}

export async function compareRequests(reqParams: AxiosRequestConfig, lastResponse?: AxiosResponse) {
    if (lastResponse && lastResponse.config.url == reqParams.url && deepEqual(reqParams.params, lastResponse.config.params)) {
        return lastResponse;
    } else {
        return await API.request(reqParams)
    }
}

export class APIBase extends Axios {
    constructor(config: (AxiosRequestConfig & { subURL?: string }) = {}) {
        super({
            ...axios.defaults,
            baseURL: `/organizations/${env.organization_id}/${config.subURL || ''}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': env.csrf_token,
                'Authorization': 'token=' + env.session_key
            },
            withCredentials: true,
            ...config,
        })
    }

    get base_url() {
        return this.defaults.baseURL;
    }

    create_href(to: string) {
        return `${this.base_url}${to}?session_key=${env.session_key}`;
    }

    request<T = any, R = AxiosResponse<T, any>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        if (config.data instanceof FormData) {
            config.headers ||= {}
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return super.request(config)
    }

    saveModel(url, data, id = data.id) {
        return API.request({
            method: id ? 'PUT' : 'POST',
            url: urlJoin(url, id || ''),
            data,
        })
    }

    fetcher<D>(url: string, { transform, ...params }: ConstructorParameters<typeof DataFetcher>['1'] & { transform?: (data) => D } = {}) {
        return new DataFetcher<D>(async (url_params) => {
            const response = await API.get(url, {
                params: { ...url_params },
            });
            let data = response.data;
            if (transform) data = await transform(data);
            data = observable(data, {})
            return data as any;
        }, params)
    }

    async download_file(file: string, { in_tab = false }: { in_tab?: boolean } = {}) {
        const tokenResponse = await this.get(`api/v1/link_token`);
        const token = tokenResponse.data['token'];

        const url = new URL(file, new URL(this.base_url, window.location as any));
        url.searchParams.set('session_token', token);

        const element = document.createElement('a');
        element.setAttribute('href', url.toString());
        if (in_tab) {
            element.setAttribute('target', '_blank');
        } else {
            element.setAttribute('download', '');
        }
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    async protectedRequest<T>(options: { action: string, form?: Form }, act: () => Promisable<T>): Promise<T | false> {
        try {
            return await act();
        } catch (ex) {
            if (isHttpError(ex, '4xx')) {
                const body = ex.response?.data as any;
                const err = body?.error || body?.message;
                if (err) {
                    AlertManager.default.addAlert(err, { level: "error", timeout: 10000 });
                }
                if (options.form && body['errors']) {
                    return transformHttpErrors(ex, options.form) as any;
                }
                if (err) return false;
            }
            let message = 'Internal error occurred';
            if (options?.action) {
                message += ` ${options.action}`;
            }
            AlertManager.default.addAlert(message, { level: "error", timeout: 10000 });
            captureException(ex);
            console.error(ex);
        }
    }
}

export function class_transformer<T, R extends Class<any>>(cls: R): (data) => InstanceType<R> {
    return (d) => new cls(d)
}

export function transform_page<T, R>(page: DataPage<T>, transform: (item: T) => R): DataPage<R> {
    return {
        ...page,
        items: page.items.map(transform),
    }
}

export function page_transformer<T, R>(transform: (item: T) => R): (data: T) => DataPage<R>
export function page_transformer<T, R extends Class<any>>(transform: R): (data) => DataPage<InstanceType<R>>
export function page_transformer<T, R>(transform) {
    return (page: DataPage<T>) => {
        if (transform.prototype) {
            return transform_page(page, (d) => new transform(d))
        } else {
            return transform_page(page, transform)
        }
    };
}

export function saveApiModel(url, data, id?) {
    return API.saveModel(url, data, id)
}

export const API = new APIBase();
// fetcherConfig.api.backend = API
