import pluralize from "pluralize";
import { ReactNode } from "react";
import { observer } from "mobx-react";

import { Flex, Text, View } from "@instructure/ui";

import { DataFetcher, DataPage } from "@inst_proserv/toolkit/util/DataFetcher";

export const TableTitle = observer(({ title, fetcher }: { title: string, fetcher?: DataFetcher<DataPage> }) => {
    let subnote: ReactNode = null;

    if (fetcher && fetcher.data) {
        subnote = <Text size="small">
            {fetcher.data.total_count} on {fetcher.data.page_count} {pluralize("page", fetcher.data.page_count)}
        </Text>
    }

    return <>
        <Flex margin="0 0 small">
            <Flex.Item>
                <Text weight="bold" transform="uppercase" size="small">{title}</Text>
            </Flex.Item>
            <Flex.Item>
                <View margin="0 0 0 small">
                    {subnote}
                </View>
            </Flex.Item>
        </Flex>
    </>
})
