
import { Component } from "react";

import { View } from "@instructure/ui-view";

import { ForceTrailingSlashes } from '@matchlighter/common_library/react_router/ForceTrailingSlashes';

import { AlertProvider, AlertsContainer } from "@toolkit/components/AlertManager";
import { ApplyCanvasTheme } from "@toolkit/components/ApplyCanvasTheme";
import { PromptContainer, PromptProvider } from "@toolkit/components/PromptManager";
import { PositionedWaterMark } from "@toolkit/components/watermark/index";
import { LtiRouter as Router } from "@toolkit/extra/LtiHistory";

import '@/bootstrap';
import '@/screen.scss';
import { env } from "@/environment";
import { AccountNavRoutes } from "@/routes/routes";

export default class AccountNavLaunch extends Component {

    render() {
        return <>
            <Router initialEntries={[env.initial_route || '/accounts/']}>
                <ForceTrailingSlashes />

                <ApplyCanvasTheme>
                    <PromptProvider>
                        <AlertProvider>
                            <PromptContainer />
                            <AlertsContainer />
                            <PositionedWaterMark />
                            {/* <LtiFitHelper mode="expand" /> */}

                            <View as="div" padding="0 small 0 large" margin="0" maxWidth="1300px">
                                <View as="div" padding="0 small">
                                    {Object.values(AccountNavRoutes)}
                                </View>
                            </View>
                        </AlertProvider>
                    </PromptProvider>
                </ApplyCanvasTheme>
            </Router>
        </>
    }
}
