import { observer } from "mobx-react";

import { Flex, Heading, IconSubaccountsLine, Text, View } from "@instructure/ui";

import { CleverType } from "@/components/CleverType";
import { Account, Spacing } from "@/types/common";
import { NotSharedPill } from "./Pills";

const VR = () => <>&nbsp;|&nbsp;</>;

export const AccountByline = ({ data, detailed }: { data: Account, detailed?: boolean }) => {
    let second = <i>Account Not Linked</i>;
    if (data.clever_link) {
        second = <>
            <b>Clever Resource:</b> {data.clever_link.resource?.name || <Text size="small" color="secondary" fontStyle="italic">Pending First Sync</Text>} (<CleverType resource={data.clever_link} />)
            <VR />
            <b>Clever ID:</b> {data.clever_link.clever_id}
            <VR />
            <b>SSO Provider:</b> <Text size="small" transform="capitalize">{data.clever_link?.sso_provider || <i>Not Configured</i>}</Text>
        </>
    }

    return <>
        <b>SIS ID:</b> {data.sis_id || <i>Missing</i>}
        {detailed && <>
            <VR />
            {second}
        </>}
    </>
}

export const AccountHeader = observer(({ account, detail, margin }: { account: Account, detail?: boolean, margin?: Spacing }) => {
    return <Flex alignItems="start" margin={margin ?? "medium 0"}>
        <Flex.Item margin="0 x-small 0 0">
            <Heading level="h3">
                <IconSubaccountsLine />
            </Heading>
        </Flex.Item>
        <Flex.Item>
            <Flex alignItems="center" margin="0 0 x-small" height={"27px"}>
                <Heading level="h3">
                    {account.name}
                </Heading>
                {detail && account.clever_link && !account.clever_link.shared && <>
                    <View margin="0 0 0 x-small">
                        <NotSharedPill />
                    </View>
                </>}
            </Flex>
            <Text as="div">
                <AccountByline data={account} detailed={detail} />
            </Text>
        </Flex.Item>
    </Flex>
})

const CleverResourceToken = ({ account }: { account: Account }) => {
    const link = account.clever_link;
    if (!link) return <i>Not Linked</i>;

    return <>
        {link.clever_id} (<CleverType resource={link} />)
    </>
}
