
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";

import { Select } from "@instructure/ui";
import { Text } from "@instructure/ui-text";
import { View } from "@instructure/ui-view";

import { toTitleCase } from '@matchlighter/common_library/strings';

// import { AsyncSelect, WAsyncSelectProps, FetchUserError } from "../components/TempAsyncSelect";
import { AsyncSelect, FetchUserError, WAsyncSelectProps } from "@toolkit/components/AsyncSelect";

import { API } from "@/api";
import { BlueprintCourse } from "@/types/common";
import { groupBy } from "@/util";

let last_acc_id;
let last_promise;

async function fetchAccountBlueprints(account: number) {
    if (last_acc_id != account) {
        last_acc_id = account;
        last_promise = null;
    }

    if (!last_promise) {
        last_promise = API.get<any>(`api/v1/accounts/${account}/blueprints`, {
            // params: { search_term: search, page: search ? '1' : 'all' },
        })
    };

    return await last_promise;
}

@observer
export class BlueprintCourseSelect extends Component<WAsyncSelectProps<BlueprintCourse> & { account_id: number }> {
    @observable accessor options;

    fetch = async (search) => {
        if (search && search.length < 3) throw new FetchUserError("Type at least 3 characters to search");

        const response = await fetchAccountBlueprints(this.props.account_id);

        let items = [];
        for (let [type, data] of Object.entries(response.data as Record<string, BlueprintCourse[]>)) {
            let i = 0;
            for (let v of data) {
                v['type'] = type;
                if (search) {
                    if (!v.name.toLowerCase().includes(search.toLowerCase())) continue;
                }
                items.push(v);
                if (++i >= 5) break;
            }
        }

        return items;
    }

    renderGroupLabel = (type: string) => {
        let label = "Gradient Primaries";

        if (type != "root") {
            label = toTitleCase(type) + " Overrides";
        }

        return <span>
            {label}
        </span>
    }

    renderOptions: WAsyncSelectProps<any>['renderOptions'] = (opts, { render_option }) => {
        const grouped = groupBy(opts, 'type');

        if (Object.keys(grouped).length == 1) {
            return opts.map(render_option);
        }

        return Object.entries(grouped).map(([type, opts]) => {
            return <Select.Group renderLabel={this.renderGroupLabel(type)}>
                {opts.map(render_option)}
            </Select.Group>
        })
    }

    render() {
        const { ...pass } = this.props
        return <AsyncSelect
            {...pass}
            options={this.fetch}
            minSearchLength={0}
            getOptionLabel={opt => opt?.name}
            renderOptions={this.renderOptions}
            // should_cache_options={false}
            // fetch_on_open="always"
            renderOption={(opt: BlueprintCourse, meta) => {
                return <>
                    <View as="div">
                        <Text>{opt.name}</Text>
                    </View>
                    <View as="div">
                        <Text size="x-small" color={meta.highlighted || meta.selected ? "secondary-inverse" : "secondary"}>{opt.sis_course_id}</Text>
                    </View>
                </>
            }}
        />
    }
}
