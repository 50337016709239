
import './screen.scss';
import { env } from './environment';

// Configure Sentry
import * as Sentry from '@sentry/browser';
if (env.environment != 'development') {
    Sentry.init({
        dsn: env.sentry_dsn,
        environment: env.environment,
    });
}

// Configure MobX
import * as mobx from 'mobx';
mobx.configure({
    enforceActions: 'observed',
})

// Configure Moment
import moment from 'moment-timezone';
moment.tz.setDefault(env.timezone || moment.tz.guess());

const console_error = console.error;
console.error = (...args) => {
    if (args[0] == "Warning: %s(...): When calling super() in `%s`, make sure to pass up the same props that your component's constructor was passed.%s" && args[1] == 'ProxyingComponent')
        return;
    console_error(...args);
}
