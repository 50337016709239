import { env } from "@toolkit/environment";

declare module "@toolkit/environment" {
    export interface Environment {
        organization_id: number;
        session_key: string;
        sentry_dsn?: string;
        environment?: string;
        timezone: string;

        current_user: any;

        params
        base_path
        canvas_url
        post_message_token: string;
        brand_variables: any;
        launch_params
        regexps?: { [key: string]: RegExp }

        root_launch?: boolean;
        initial_route?: string;

        permissions: {
            super_user: boolean;
            [key: string]: any;
        }
    }
}

if (env.regexps) {
    const regexes = {}
    for (let [k, str] of Object.entries(env.regexps)) {
        regexes[k] = new RegExp(str)
    }
    env.regexps = regexes;
}

if (env.environment == 'development') {
    window['ENV'] = env;
    console.log(env);
}

export { env }
