import { observer } from "mobx-react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Breadcrumb, Flex, View } from "@instructure/ui";

import { env } from "@toolkit/environment";

import { API } from "@/api";
import { SmartLink } from "@/components/SmartLink";
import { Account, getAccountType } from "@/types/account";
import { AccountHeader } from "./components/AccountHeader";
import { CoursesTable } from "./components/CoursesTable";
import { SetupButton } from "./components/SetupButton";

@observer
export class SubAccountView extends Component<RouteComponentProps<{ account }>> {
    account_fetcher = API.fetcher<Account>(`api/v1/accounts/${this.props.match.params.account}`, {})

    get account() { return this.account_fetcher.data }

    componentDidMount(): void {
        this.account_fetcher.activate();
    }

    renderCoursesTable() {
        if (this.account.clever_link?.type == "CleverDistrict") {
            return <>
                <View as="div" textAlign="center" margin="large">
                    {/* This Account is linked to Clever District {this.account.clever_link.resource?.name} ({this.account.clever_link.clever_id}). */}
                    No further configuration is available.
                </View>
            </>
        }

        if (!this.account.clever_link) {
            if (getAccountType(this.account) == "School" && env.permissions.super_user) {
                return <>
                    <View as="div" textAlign="center" margin="large">
                        <View margin="small">
                            <div>This account has not yet been paired to a Clever School</div>
                        </View>
                        <div>
                            <SetupButton account={this.account} />
                        </div>
                    </View>
                </>
            }

            return <>
                <View as="div" textAlign="center" margin="large">
                    <div>This account has not been configured for use with Clever</div>
                </View>
            </>
        }

        return <CoursesTable account={this.account} />
    }

    render() {
        if (this.account_fetcher.fetchStatus != "loaded") return null;

        return <>
            {env.root_launch && <>
                <Breadcrumb label="You are here:">
                    <SmartLink to={`/accounts/`}>Index</SmartLink>
                    <Breadcrumb.Link>{this.account.name}</Breadcrumb.Link>
                </Breadcrumb>
            </>}

            <Flex>
                <Flex.Item shouldGrow>
                    <AccountHeader account={this.account} detail />
                </Flex.Item>
            </Flex>

            {this.renderCoursesTable()}
        </>
    }
}
