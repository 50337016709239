import { action, computed, observable, runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";
import { Prompt } from "react-router";

import { Button, Flex, IconBlueprintLine, IconCheckMarkIndeterminateLine, IconCompleteLine, IconCoursesLine, IconUserLine, Table as InstTable, Link, Text, View } from "@instructure/ui";

import { ATColumnSpec, AsyncTable } from "@toolkit/components/AsyncTable";
import { WorkingButton } from "@toolkit/components/WorkingButton";
import { env } from "@toolkit/environment";
import { DataPage } from "@toolkit/util/DataFetcher";
import { emulate_instui } from "@toolkit/util/index";

import { API } from "@/api";
import { TableTitle } from "@/components/TableTitle";
import { Account } from "@/types/account";
import { BlueprintCourse, CleverUser, Course } from "@/types/common";
import { BlueprintCourseSelect } from "./BlueprintCourseSelect";

const COLUMNS: ATColumnSpec[] = [
    { key: "name", title: "Canvas Course" },
    { key: "teacher", title: "Teacher" },
    { key: "blueprint", title: "Blueprint Parent Course" },
    { key: "actions", title: "", textAlign: "end", width: "200px" },
]

const CourseTile = observer(({ course }: { course: Course }) => {
    return <>
        <Flex alignItems="baseline">
            <Flex.Item>
                <View display="block" margin="0 x-small 0 0">
                    <Link target="_blank" href={`${env.canvas_url}/courses/${course.id}/settings`}>
                        {course.is_blueprint ? <IconBlueprintLine /> : <IconCoursesLine />}
                    </Link>
                </View>
            </Flex.Item>

            <Flex.Item>
                {course.name}

                <View as="div" margin="x-small 0 0 0">
                    <Text as="div" size="small">
                        <b>{course.is_blueprint ? "Blueprint SIS" : "Clever"} ID:</b> {course.sis_id || <i>None</i>}
                    </Text>
                </View>
            </Flex.Item>
        </Flex>
    </>
});
const UserTile = observer(({ user }: { user: CleverUser }) => {
    return <>
        <Flex alignItems="baseline">
            <Flex.Item>
                <View display="block" margin="0 x-small 0 0">
                    <Link target="_blank" href={`${env.canvas_url}/users/sis_user_id:${user.clever_id}`}>
                        <IconUserLine />
                    </Link>
                </View>
            </Flex.Item>

            <Flex.Item>
                {user.name.last}, {user.name.first} {user.name.middle}

                <View as="div" margin="x-small 0 0 0">
                    <Text as="div" size="small">
                        <b>SIS ID:</b> {user.roles?.teacher?.sis_id || <i>None</i>}
                    </Text>
                </View>
            </Flex.Item>
        </Flex>
    </>
});

@observer
export class CoursesTable extends Component<{ account: Account }> {
    courses_fetcher = API.fetcher<DataPage>(`api/v1/accounts/${this.props.account.id}/courses?page_size=10`, {});

    @observable accessor blueprints_map: Record<number, BlueprintCourse> = {};

    @action.bound
    promptNavigation(location: any, action: string) {
        if (Object.values(this.blueprints_map).filter(v => !!v).length) {
            return "You have unsaved changes. Are you sure you want to leave?";
        }
        return true;
    }

    render() {
        if (!this.props.account.clever_link) {
            return <>
                <TableTitle title="Blueprint Provisions" />
                <View as="div" textAlign="center" padding="medium">This account is not linked to Clever</View>
            </>
        }

        return <>
            <TableTitle title="Blueprint Provisions" fetcher={this.courses_fetcher} />

            <Prompt message={this.promptNavigation} />

            <AsyncTable
                hover
                columns={COLUMNS}
                fetcher={this.courses_fetcher}
                renderRow={data => <Row data={data} account={this.props.account} blueprint_map={this.blueprints_map} />}
            />
        </>
    }
}

@observer
@emulate_instui(InstTable.Row)
class Row extends Component<{ data: Course, account: Account, blueprint_map: Record<number, BlueprintCourse> }, {}> {
    @computed get selected_blueprint() {
        return this.props.blueprint_map[this.props.data.id];
    }

    @action.bound
    handleBlueprintSelected(bp: BlueprintCourse) {
        this.props.blueprint_map[this.props.data.id] = bp;
    }

    @action.bound
    async handleLinkClicked() {
        const course = this.props.data;
        await API.protectedRequest({ action: "linking Blueprint" }, async () => {
            const response = await API.post(`api/v1/courses/${this.props.data.id}/blueprint`, { blueprint_course_id: this.selected_blueprint.id });
            runInAction(() => {
                console.log(toJS(course), response.data)
                Object.assign(course, response.data);
                delete this.props.blueprint_map[course.id];
            })
        })
    }

    @action.bound
    handleCancelClicked() {
        delete this.props.blueprint_map[this.props.data.id];
    }

    renderBlueprintCell() {
        const { data: row, ...rest } = this.props;
        if (row.blueprint_course) {
            const bp = row.blueprint_course;
            return <>
                <CourseTile course={bp} />
            </>
        }

        return <BlueprintCourseSelect
            renderLabel=""
            account_id={this.props.account.id}
            // initialValue={row.blueprint_course}
            value={this.selected_blueprint || null}
            onValueChanged={this.handleBlueprintSelected}
        />
    }

    renderActionCell() {
        const { data: row, ...rest } = this.props;

        if (row.blueprint_course) {
            return <>
                <IconCompleteLine /> Linked
            </>
        }

        if (this.selected_blueprint) {
            return <>
                <Button onClick={this.handleCancelClicked}>Cancel</Button>
                &nbsp;
                <WorkingButton onClick={this.handleLinkClicked} color="primary">Link</WorkingButton>
            </>
        }

        return <IconCheckMarkIndeterminateLine />;
    }

    render() {
        const { data: row, ...rest } = this.props;
        return <>
            <InstTable.Row {...rest}>
                <InstTable.Cell>
                    <CourseTile course={row} />
                </InstTable.Cell>

                <InstTable.Cell>
                    <UserTile user={row.teachers[0]} />
                </InstTable.Cell>

                <InstTable.Cell>
                    {this.renderBlueprintCell()}
                </InstTable.Cell>

                <InstTable.Cell textAlign="end">
                    {this.renderActionCell()}
                </InstTable.Cell>
            </InstTable.Row>
        </>
    }
}

