
import { Account } from "@/types/common";
import { IconWarningLine, Pill, Tooltip } from "@instructure/ui";

export const NotSharedPill = () => {
    return <>
        {/* <Tooltip renderTip="This Account has been configured to sync from Clever, but the District has not properly shared their data"> */}
            <Pill color="warning">Sharing Not Configured</Pill>
        {/* </Tooltip> */}
    </>
};
export const NoSisIdPill = () => {
    return <>
        {/* <Tooltip renderTip="Canvas Accounts must have a SIS ID before they can be linked to Clever Resources. If a SIS ID is set, it may take up to 24H to be noted"> */}
            <Pill color="danger"><IconWarningLine /> SIS ID Not Found</Pill>
        {/* </Tooltip> */}
    </>
};
export const PendingFirstPill = () => {
    return <>
        {/* <Tooltip renderTip="This Account is configured, but hasn't been synced from Clever yet"> */}
            <Pill color="alert">Pending First Sync</Pill>
        {/* </Tooltip> */}
    </>
};

export const AccountStatusPill = ({ account }: { account: Account }) => {
    if (!account.sis_id) return <NoSisIdPill />;
    if (account.clever_link) {
        if (!account.clever_link.shared) return <NotSharedPill />;
        if (!account.clever_link.resource) return <PendingFirstPill />;
    }
    return null;
}
